<template>
  <div class="container" @contextmenu="preventRightClick">
    <!-- 左边内容 -->
    <div class="leftContainer">
      <!-- 左边顶部 -->
      <div class="leftTopContainer">
        <!-- logo -->
        <img src="@/assets/imgs/logo_row.png" class="logoImg">
        <!-- 头像 -->
        <img :src="userInfo.avatar" class="avatarImg" v-if="userInfo.avatar">
        <img src="@/assets/imgs/avatar.png" class="avatarImg" v-else>
        <!-- 名字 -->
        <div class="nameText">{{ userInfo.nickName }}</div>
        <!-- 菜单 -->
        <div v-for="(item, index) in menuList" :key="item.label" class="menuItemContainer" @click="menuClick(index)">
          <img :src="index == currentMenuIndex ? item.iconSelect : item.icon" class="menuImg">
          <div class="menuText" :style="index == currentMenuIndex ? 'color: #00AAFF' : ''">{{ item.label }}</div>
        </div>
      </div>
      <!-- 左边底部 -->
      <div class="leftBottomContainer">
        <div class="diskContainer">
          <div>磁盘空间</div>
          <div>
            <span>{{ diskInfo.driveUsedSpace + "G / " }}</span>
            <span>{{ diskInfo.driveTotalSize + "G" }}</span>
          </div>
        </div>
        <div class="systemSetupContainer" @click="currentMenuIndex = 4">
          <img :src="currentMenuIndex == 4 ? systemSetupMenu.iconSelect : systemSetupMenu.icon" class="systemSetupImg">
          <div :style="currentMenuIndex == 4 ? 'color: #00AAFF' : ''">{{ systemSetupMenu.label }}</div>
        </div>
        <div style="font-size: 12px; color: #aaa;margin-top: 8px;">版本号：{{ configInfo.SerialNumber }}</div>
      </div>
    </div>
    <!-- 右边区域 -->
    <div class="rightContainer">
      <!-- 窗体控制栏 -->
      <div class="windowCtrlContainer">
        <img class="windowCtrlImg" src="@/assets/imgs/min.png" @click="minsize" />
        <img class="windowCtrlImg" v-if="togglemaxsizeFlag == 'normal'" src="@/assets/imgs/max.png"
          @click="togglemaxsize" />
        <img class="windowCtrlImg" v-else src="@/assets/imgs/normal.png" @click="togglemaxsize" />
        <img class="windowCtrlImg" src="@/assets/imgs/close.png" @click="close" />
      </div>
      <!-- 内容区域 -->
      <!-- 主播列表 -->
      <compere-list v-if="currentMenuIndex == 0" @updateMenuIndex="updateMenuIndex"></compere-list>
      <!-- 添加主播 -->
      <compere-add v-if="currentMenuIndex == 1" @updateMenuIndex="updateMenuIndex"></compere-add>
      <!-- 直播复盘 -->
      <replay v-if="currentMenuIndex == 2" :key="refresh"></replay>
      <!-- 系统设置 -->
      <setup v-if="currentMenuIndex == 4" @updateUserInfo="updateUserInfo"></setup>
    </div>
  </div>
</template>

<script>
import compereList from '../compere/compere-list.vue';
import compereAdd from '../compere/compere-add.vue'
import setup from '../setup/index.vue';
import replay from '../replay/replay.vue';
export default {
  components: {
    compereList,
    setup,
    compereAdd,
    replay
  },
  data() {
    return {
      userInfo: {},
      togglemaxsizeFlag: 'normal',
      currentMenuIndex: 0,
      menuList: [
        {
          label: "主播列表",
          icon: require("@/assets/imgs/zhubo_list.png"),
          iconSelect: require("@/assets/imgs/zhubo_list_select.png"),
        },
        {
          label: "添加主播",
          icon: require("@/assets/imgs/zhubo_add.png"),
          iconSelect: require("@/assets/imgs/zhubo_add_select.png"),
        },
        {
          label: "直播复盘",
          icon: require("@/assets/imgs/zhibo_replay.png"),
          iconSelect: require("@/assets/imgs/zhibo_replay_select.png"),
        },
      ],
      systemSetupMenu: {
        label: "系统设置",
        icon: require("@/assets/imgs/system_setup.png"),
        iconSelect: require("@/assets/imgs/system_setup_select.png"),
      },
      diskInfo: {
        driveTotalSize: 0,
        driveUsedSpace: 0,
        driveAvailablepace: 0
      },
      refresh: false,
      diskTimer: null,
      userTimer: null,
      configInfo: {},
    }
  },
  created() {

    // 禁止用户按F5和F12
    window.addEventListener("keydown", (event) => {
      if (event.key === 'F12' || event.key === 'F5') {
        event.preventDefault()
      }
    });

    let homeMenu = this.$store.state.homeMenu;
    this.userInfo = this.$store.state.userInfo;

    if (homeMenu) {
      this.currentMenuIndex = homeMenu;
      this.$store.commit("saveHomeMenu", null);
    }

    // 开启读盘空间定时器
    if (this.diskTimer) {
      clearInterval(this.diskTimer)
    }
    this.diskTimer = null;
    this.startTimer();
    // 开启读用户信息定时器
    if (this.userTimer) {
      clearInterval(this.userTimer)
    }
    this.userTimer = null;
    this.startUserTimer();

  },
  beforeDestroy() {
    // 清空定时器
    if (this.diskTimer) {
      clearInterval(this.diskTimer)
    }
    this.diskTimer = null;
    if (this.userTimer) {
      clearInterval(this.userTimer)
    }
    this.userTimer = null;
  },
  methods: {
    menuClick(menuIndex) {
      this.currentMenuIndex = menuIndex;
      this.$nextTick(() => {
        if (menuIndex == 2) {
          this.refresh = !this.refresh;
        }
      })
    },
    // 获取基本设置信息
    getBasinSetupInfo() {
      this.$httpClient.setup.getmodel({}).then((res) => {
        if (res.code == 0) {
          this.configInfo = res.data;
        }
      });
    },
    // 读盘空间
    getDisk() {
      this.$httpClient.setup.getdisksize({}).then(res => {
        if (res.code == 0 && res.data) {
          this.diskInfo = res.data;
          if (this.diskInfo.driveAvailablepace < 10) {
            // 磁盘空间不足10G
            if (this.configInfo && this.configInfo.IsRocord == 1) {
              // 当前正在录制
              this.$confirm('磁盘可用空间已不足10G，是否停止录制？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                // 去列表页停止检测
                this.$store.commit("saveStopRecord", true);
                this.currentMenuIndex = 0;
              })
            }
          }
        }
      })
    },
    // 开启读盘空间定时器
    startTimer() {
      this.getBasinSetupInfo();
      this.getDisk();
      this.diskTimer = setInterval(() => {
        this.getBasinSetupInfo();
        this.getDisk();
      }, 5 * 60 * 1000);
    },
    // 开启用户信息定时器，如果被冻结，跳转到登录页
    startUserTimer() {
      this.userTimer = setInterval(() => {
        this.$httpBack.user.checkFreeze({}).then((res) => {
          // console.log(res);
        })
      }, 5000);
    },
    // 禁止鼠标右键
    preventRightClick(event) {
      event.preventDefault();
    },
    // 更新用户信息
    updateUserInfo() {
      this.userInfo = this.$store.state.userInfo;
    },
    // 更新菜单索引
    updateMenuIndex(menuIndex) {
      this.currentMenuIndex = menuIndex;
    },
    // 最大化/恢复正常
    togglemaxsize() {
      this.$httpClient.form.togglemaxsize().then((res) => {
        if (res.code == 0) {
          if (this.togglemaxsizeFlag == 'normal') {
            this.togglemaxsizeFlag = 'max';
          } else {
            this.togglemaxsizeFlag = 'normal';
          }
        }
      })
    },
    // 最小化
    minsize() {
      this.$httpClient.form.minsize().then((res) => {
        console.log("最小化")
      })
    },
    // 关闭
    close() {
      this.$httpClient.setup.getmodel({}).then((res) => {
        if (res.data.IsRocord == 1) {
          this.$confirm('目前正在录制中，关闭后会停止录制，是否确认关闭?', '提示!!!', {
            distinguishCancelAndClose: true,
            confirmButtonText: '取消',
            cancelButtonText: '确定',
            type: 'warning'
          }).then(() => {

          }).catch((action) => {
            if (action == "cancel") {
              this.$httpBack.user.logout().then(res => {
                console.log("登出")
              });
              this.$httpClient.video.stopAutoAnalysis().then((res) => {
                console.log("停止自动分析")
                this.$httpClient.form.close().then((res) => {
                  // console.log("关闭")
                });
              });

            }

          });
        } else {
          this.$httpBack.user.logout().then(res => {
            console.log("登出")
          });
          this.$httpClient.video.stopAutoAnalysis().then((res) => {
            console.log("停止自动分析")
            this.$httpClient.form.close().then((res) => {
              console.log("关闭")
            });
          });

        }
      });


    },
  },
};
</script>

<style scoped>
.cancelButtonClass {
  color: red;
}

.windowCtrlImg {
  width: 16px;
  height: 16px;
  margin-left: 21px;
  cursor: pointer;
}

.windowCtrlContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rightContainer {
  padding: 18px;
  box-sizing: border-box;
  width: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.systemSetupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #2E3742;
  margin-top: 16px;
  cursor: pointer;
}

.systemSetupImg {
  width: 28px;
  height: 28px;
}

.diskContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #2E3742;
}

.leftBottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.menuText {
  font-size: 12px;
  color: #2E3742;
}

.menuImg {
  width: 28px;
  height: 28px;
}

.menuItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}

.nameText {
  font-size: 14px;
  color: #2E3742;
  margin-top: 4px;
  cursor: pointer;
}

.avatarImg {
  width: 40px;
  height: 40px;
  margin-top: 26px;
  border-radius: 50%;
  cursor: pointer;
}

.logoImg {
  width: 56px;
  height: 56px;
  margin-top: 24px;
}

.leftTopContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftContainer {
  width: 120px;
  height: 100%;
  background: #F4F9FF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.container {
  display: flex;
  border: 1px solid #ccc;
  height: calc(100vh - 2px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>